import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import firebase from "firebase/app";
import "firebase/analytics";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment["firebaseConfig"]) {
  firebase.initializeApp( environment["firebaseConfig"] );
  const analytics = firebase.analytics();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
