import { Injectable } from '@angular/core';
import { StrapiService } from '../strapi/strapi.service';
import { Agency } from 'src/app/entities/agency';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private strapiService: StrapiService) { }

  async login(username: string, password: string){
    let response;
    await this.strapiService.login(username, password)
      .then(data => response = {user: data.user})
      .catch(error => response = {error: error.message});
    return response;
  }

  public async getLoggedInUser(){
    let response;
    await this.strapiService.getLoggedInUser()
      .then(data => response = {user: data})
      .catch(error => response = {error: error.message});
    return response;
  }

  public async logout(){
    await this.strapiService.logout();
  }

  async isLoggedIn() {
    let token = localStorage.getItem("jwt");
    if (!token) return false;

    let data = await this.getLoggedInUser();
    if (data.error) {
      this.logout();
      return false;
    }
    return true;
  }

  async getRole() {
    let data = await this.getLoggedInUser();
    if (data.error) return data;
    return {role: data.user.role.name};
  }

  async getAgencyId(){
    let data = await this.getLoggedInUser();
    if (data.error) return data;
    return {agencyid: data.user.agency};
  }

  async getAgency() {
    let data = await this.getLoggedInUser();
    if (data.error) return data;
    let agencies = await this.strapiService.getAgencies() as Agency[];
    return {agency: agencies.filter(ag => ag.agencyid == data.user.agency)[0].name1};
  }
}

class User {
  blocked?:boolean;
  confirmed:number;
  created_at:string;
  email:string;
  id:number;
  provider: string;
  role: Role;
  updated_at: string;
  username: string;
  constructor(){
    this.role = new Role();
  }
}

class Role {
  description: string;
  id: number;
  name: string;
  type: string;
}
