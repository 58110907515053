import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuardService } from './services/login-guard/login-guard.service';
import { AgencyGuardService } from './services/agency-guard/agency-guard.service';
import { IclGuardService } from './services/icl-guard/icl-guard.service';
import { PlantGuardService } from './services/plant-guard/plant-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'prefix' },
  { path: 'iclManager',
    children: [
      { path: '', redirectTo: 'orders', pathMatch: 'prefix' },
      { path: 'orders', loadChildren: './iclApp/screens/orders/orders.module#OrdersModule' },
      { path: 'agencies', loadChildren: './iclApp/screens/agencies/agencies.module#AgenciesModule' },
      { path: 'users', loadChildren: './iclApp/screens/users/users.module#UsersModule' },
    ],
    canActivate: [IclGuardService]
  },{
    path: 'agency',
    children: [
      { path: '', redirectTo: 'orders', pathMatch: 'prefix' },
      { path: 'orders', loadChildren: './agencyApp/screens/orders/orders.module#OrdersModule' },
      { path: 'trucks', loadChildren: './agencyApp/screens/trucks/trucks.module#TrucksModule' },
      { path: 'trailers', loadChildren: './agencyApp/screens/trailers/trailers.module#TrailersModule' },
      { path: 'drivers', loadChildren: './agencyApp/screens/drivers/drivers.module#DriversModule' },
      { path: 'allocations', loadChildren: './agencyApp/screens/allocations/allocations.module#AllocationsModule' },
    ],
    canActivate: [AgencyGuardService]
  },{
    path: 'plant', 
    loadChildren: './plantApp/screens/plants/plants.module#PlantsModule',
    canActivate: [PlantGuardService]
  },{
    path: 'login',
    loadChildren:'./screens/login/login.module#LoginModule',
    canActivate: [LoginGuardService]
  },
  { path: '**', redirectTo: 'login', pathMatch: 'prefix' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }