import { Injectable } from '@angular/core';
import { UserService } from "../user/user.service";
import { Router, RouterStateSnapshot, CanActivate } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService implements CanActivate {

  constructor(private userService: UserService, private router: Router) { }

  async canActivate(route, state: RouterStateSnapshot) {
    let userLoggedIn = await this.userService.isLoggedIn();
    if (!userLoggedIn) return true;

    let data = await this.userService.getRole();
    if(data.error) alert(data.error.message)
    else{
      let role = data.role;
      if (role == 'plantManager') this.router.navigate(['plant']);
      else if (role == 'agencyUser') this.router.navigate(['agency']);
      else if (role == 'iclManager') this.router.navigate(['iclManager']);
      else if(role == 'admin') this.router.navigate(['iclManager']);
      else return true;

      return false;
    }
  }
}




