import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

import Strapi from "strapi-sdk-javascript";
import { EmailValidator } from "@angular/forms";
import { Driver } from "src/app/entities/driver";
import { Truck } from "src/app/entities/truck";
import { Trailer } from "src/app/entities/trailer";
import { Allocation } from "src/app/entities/allocation";
import { Order } from 'src/app/entities/order';
const strapi = new Strapi(environment.strapiUrl);

@Injectable({
  providedIn: "root",
})
export class StrapiService {
  private ALLOCATION_CREATED_STATUS = "010";
  private ALLOCATION_ICL_DELETED_STATUS = "030";
  private ALLOCATION_AGENCY_DELETED_STATUS = "020";
  private ACTIVE_STATUS = "active";
  private DELETED_STATUS = "deleted";

  constructor() {}

  public async login(username, password) {
    let token = localStorage.getItem("jwt");
    if (!token) {
      return await strapi.login(username, password);
    }
  }

  public async logout() {
    await strapi.clearToken();
  }

  public async getOrders(params?: any) {
    return await strapi.getEntries("orders", { _limit: -1, ...params });
  }

  public async getOrderItems(params?: any) {
    return await strapi.getEntries("orderitems", { _limit: -1, ...params });
  }

  public async getOrderItemDemands(params?: any) {
    return await strapi.getEntries("orderitemdemands", {
      status: "active",
      _limit: -1,
      ...params,
    });
  }

  public async getAllocations(params?: any) {
    return await strapi.getEntries("allocations", {
      status_ne: "020",
      _limit: -1,
      ...params,
    });
  }

  public async countAllocations(params?: any) {
    return await strapi.getEntryCount("allocations", {
      status_ne: "020",
      _limit: -1,
      ...params,
    });
  }

  public async getAllAllocations(params?: any) {
    return await strapi.getEntries("allocations", { _limit: -1, ...params });
  }

  public async getTrucks(params?: any) {
    return await strapi.getEntries("trucks", { _limit: -1, ...params });
  }

  public async getDrivers(params?: any) {
    return await strapi.getEntries("drivers", { _limit: -1, ...params });
  }

  public async getPlants(params?: any) {
    return await strapi.getEntries("plants", { _limit: -1, ...params });
  }

  public async getPlantsAvailabilities(params?: any) {
    return await strapi.getEntries("plantsavailabilities", {
      _limit: -1,
      ...params,
    });
  }

  public async getPlantsExceptions(params?: any) {
    return await strapi.getEntries("plantsexceptions", {
      _limit: -1,
      ...params,
    });
  }

  public async getAgencies(params?: any) {
    return await strapi.getEntries("agencies", { _limit: -1, ...params });
  }

  public async getUsers(params?: any) {
    return await strapi.getEntries("users", {
      "role.name": "agencyUser",
      _limit: -1,
      ...params,
    });
  }

  public async getTrailers(params?: any) {
    return await strapi.getEntries("trailers", { _limit: -1, ...params });
  }

  public async createAllocation(
    webId,
    orderId,
    customerOrderNumber,
    plantId,
    agency,
    driver: Driver,
    scheduledId,
    allocationDate,
    itemId: number,
    truck: Truck,
    trailer: Trailer
  ) {
    let order = (await this.getOrders({ orderid: orderId })) as Order[];

    return await strapi.createEntry("allocations", {
      webid: webId,
      orderid: orderId,
      customerordernumber: customerOrderNumber,
      plant: plantId,
      agency: agency,
      orderitemdemand: scheduledId,
      date: allocationDate,
      item: itemId,
      driveridentificationnumber: driver.driveridentificationnumber,
      drivinglicenseexpirationdate: driver.drivinglicenseexpirationdate,
      truckplate: truck.truckplate,
      transportcardname: truck.transportcardname,
      cardvaliditydate: truck.cardvaliditydate,
      postalcode: truck.postalcode,
      street: truck.street,
      city: order[0].shiptopartyjson.City,
      country: truck.country,
      taxidentificationnumber: truck.taxidentificationnumber,
      pma: truck.pma,
      trailerplate: trailer.trailerplate,
      typeoftrailer: trailer.typeoftrailer,
      status: this.ALLOCATION_CREATED_STATUS,
      statusdate: new Date().getTime(),
    });
  }

  public async updateAllocation(
    id: any,
    driver: string,
    truck: string,
    trailer: string
  ) {
    console.log('[strapi.service:updateAllocation]',id, driver, trailer, truck);
    return strapi.updateEntry("allocations", id, {
      driveridentificationnumber: driver,
      truckplate: truck,
      trailerplate: trailer,
      status: this.ALLOCATION_CREATED_STATUS,
      statusdate: new Date().getTime(),
    });
  }

  public async createOrderItemDemands(
    itemId,
    startDate,
    endDate,
    agency,
    numOfTrucks
  ) {
    return await strapi.createEntry("orderitemdemands", {
      item: itemId,
      startdate: startDate,
      enddate: endDate,
      agency: agency,
      numoftrucks: numOfTrucks,
      status: this.ACTIVE_STATUS,
      statusdate: new Date().getTime(),
    });
  }
  public async deleteLoadedItemDeamand(
    orderItemDemandId,
    tructsLoad,
    tructsAssigned
  ) {
    return strapi.updateEntry("orderitemdemands", orderItemDemandId, {
      // changeNumOfTrucks:
      numoftrucks: tructsLoad + tructsAssigned,
    });
  }
  public async deleteOrderItemDemands(orderItemDemandId) {
    return await strapi.updateEntry("orderitemdemands", orderItemDemandId, {
      status: this.DELETED_STATUS,
      statusdate: new Date().getTime(),
    });
  }

  public async deleteAllocationByICL(allocationId, allocation: Allocation) {
    let order = (await this.getOrders({ orderid: allocation.orderid })) as Order[];
    let customer = "";
    if(order.length > 0){
      customer = order[0].shiptoparty + " ";
      if(order[0].shiptopartyjson.Name1) customer += order[0].shiptopartyjson.Name1;
    }

    return await strapi.updateEntry("allocations", allocationId, {
      status: this.ALLOCATION_ICL_DELETED_STATUS,
      statusdate: new Date().getTime(),
      agency: allocation.agency,
      truckplate: allocation.truckplate,
      trailerplate: allocation.trailerplate,
      driveridentificationnumber: allocation.driveridentificationnumber,
      date: allocation.date,
      plant: allocation.plant,
      orderid: allocation.orderid,
      customer,
      webid: allocation.webid,
      city: order[0].shiptopartyjson.City
    });
  }

  public async deleteAllocationByAgency(allocationId) {
    return strapi.updateEntry("allocations", allocationId, {
      status: this.ALLOCATION_AGENCY_DELETED_STATUS,
      statusdate: new Date().getTime(),
    });
  }

  public async createPlant(
    plantId: string,
    plantName: string,
    description: string,
    length: number,
    capacity: number
  ) {
    return await strapi.createEntry("plants", {
      plantid: plantId,
      name: plantName,
      description: description,
      slotLength: length,
      plantCapacity: capacity,
    });
  }

  public async updatePlant(
    id: any,
    length: number,
    capacity: number,
    name: string,
    description: string
  ) {
    return await strapi.updateEntry("plants", id, {
      name: name,
      description: description,
      slotLength: length,
      plantCapacity: capacity,
    });
  }

  public async deletePlantAvailability(id: number) {
    return await strapi.deleteEntry("plantsavailabilities", id + "");
  }

  public async createPlantAvailability(
    plantId: number,
    day: number,
    starttime: string,
    endtime: string
  ) {
    return await strapi.createEntry("plantsavailabilities", {
      plantid: plantId,
      day: day,
      starttime: starttime,
      endtime: endtime,
    });
  }

  public async createPlantException(
    plantid: number,
    startdate: number,
    enddate?: number,
    starttime?: string,
    endtime?: string
  ) {
    return await strapi.createEntry("plantsexceptions", {
      plantid: plantid,
      startdate: startdate,
      enddate: enddate,
      starttime: starttime,
      endtime: endtime,
      isactive: 0,
    });
  }

  public async deletePlantException(id: number) {
    return await strapi.deleteEntry("plantsexceptions", id + "");
  }

  public async deletePlant(id: number) {
    return await strapi.deleteEntry("plants", id + "");
  }

  public async deleteTruck(id: any) {
    return await strapi.updateEntry("trucks", id, {
      status: this.DELETED_STATUS,
      statusdate: new Date().getTime(),
    });
  }

  public async createTruck(
    truckplate: string,
    pma: string,
    postalcode: string,
    street: string,
    city: string,
    country: string,
    taxidentificationnumber: string,
    transportcardname: string,
    cardvaliditydate: number,
    agencyid: string
  ) {
    return await strapi.createEntry("trucks", {
      truckplate: truckplate,
      pma: pma,
      postalcode: postalcode,
      street: street,
      city: city,
      country: country,
      taxidentificationnumber: taxidentificationnumber,
      transportcardname: transportcardname,
      cardvaliditydate: cardvaliditydate,
      agency: agencyid,
      status: this.ACTIVE_STATUS,
      statusdate: new Date().getTime(),
    });
  }

  public async updateTruck(
    id: any,
    pma?: string,
    postalcode?: string,
    street?: string,
    city?: string,
    country?: string,
    taxidentificationnumber?: string,
    transportcardname?: string,
    cardvaliditydate?: number
  ) {
    let data: any = {};
    if (pma) data.pma = pma;
    if (postalcode) data.postalcode = postalcode;
    if (street) data.street = street;
    if (city) data.city = city;
    if (country) data.country = country;
    if (taxidentificationnumber)
      data.taxidentificationnumber = taxidentificationnumber;
    if (transportcardname) data.transportcardname = transportcardname;
    if (cardvaliditydate || cardvaliditydate == 0)
      data.cardvaliditydate = cardvaliditydate;
    return await strapi.updateEntry("trucks", id, data);
  }

  public async deleteTrailer(id: any) {
    return await strapi.updateEntry("trailers", id, {
      status: this.DELETED_STATUS,
      statusdate: new Date().getTime(),
    });
  }

  public async createTrailer(
    trailerplate: string,
    typeoftrailer: string,
    agencyid: string
  ) {
    return await strapi.createEntry("trailers", {
      trailerplate: trailerplate,
      typeoftrailer: typeoftrailer,
      agency: agencyid,
      status: this.ACTIVE_STATUS,
      statusdate: new Date().getTime(),
    });
  }

  public async deleteDriver(id: any) {
    return await strapi.updateEntry("drivers", id, {
      status: this.DELETED_STATUS,
      statusdate: new Date().getTime(),
    });
  }

  public async createDriver(
    drivinglicenseexpirationdate: number,
    driveridentificationnumber: string,
    agencyid: string
  ) {
    return await strapi.createEntry("drivers", {
      drivinglicenseexpirationdate: drivinglicenseexpirationdate,
      driveridentificationnumber: driveridentificationnumber,
      agency: agencyid,
      status: this.ACTIVE_STATUS,
      statusdate: new Date().getTime(),
    });
  }

  public async updateDriver(id: any, drivinglicenseexpirationdate?: number) {
    let data: any = {};
    if (drivinglicenseexpirationdate)
      data.drivinglicenseexpirationdate = drivinglicenseexpirationdate;
    return await strapi.updateEntry("drivers", id, data);
  }

  public async deleteAgency(id: any) {
    return await strapi.updateEntry("agencies", id, {
      status: this.DELETED_STATUS,
      statusdate: new Date().getTime(),
    });
  }

  public async createAgency(agencyid: string, name1: string) {
    return await strapi.createEntry("agencies", {
      agencyid: agencyid,
      name1: name1,
      status: this.ACTIVE_STATUS,
      statusdate: new Date().getTime(),
    });
  }

  public async updateAgency(id: any, agencyid?: string, name1?: string) {
    let data: any = {};
    if (agencyid) data.agencyid = agencyid;
    if (name1) data.name1 = name1;

    return await strapi.updateEntry("agencies", id,  data);
  }

  public async createUser(
    username: string,
    email: string,
    password: string,
    agency: string
  ) {
    return await strapi.createEntry("users", {
      username: username,
      email: email,
      password: password,
      agency: agency,
      role: {
        id: environment.agencyUserRoleId,
        name: "agencyUser",
      },
    });
  }

  public async deleteUser(id: number) {
    return await strapi.deleteEntry("users", id + "");
  }

  public async updateUser(id: number, password: string) {
    return await strapi.updateEntry("users", id + "", {
      password: password,
    });
  }

  public async blockUser(id: number) {
    return await strapi.updateEntry("users", id + "", {
      blocked: 1,
    });
  }

  public async getLoggedInUser() {
    return await strapi.getEntries("users/me");
  }

  public async updateOrderItem(id: any, data) {
    return await strapi.updateEntry("orderitems", id, data);
  }
}
