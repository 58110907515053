// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  strapiUrl: "https://api.iberpotash.appsb.icldig.icl-group.com/",
  agencyUserRoleId: 4,
  firebaseConfig: {
    apiKey: "AIzaSyDuVSata8f9QhBzu0mviW68_0DUpasmEC8",
    authDomain: "icl-truck-portal.firebaseapp.com",
    databaseURL: "https://icl-truck-portal.firebaseio.com",
    projectId: "icl-truck-portal",
    storageBucket: "icl-truck-portal.appspot.com",
    messagingSenderId: "694133228932",
    appId: "1:694133228932:web:d6a1af2d8ccc064cea89d1",
    measurementId: "G-CB8N4EFN84"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
