import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { Router, RouterStateSnapshot, CanActivate } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class IclGuardService implements CanActivate {

  constructor(private userService: UserService, private router: Router) { }

  async canActivate(route, state: RouterStateSnapshot) {
    let userLoggedIn = await this.userService.isLoggedIn();
    if (userLoggedIn) {
      let data = await this.userService.getRole();
      if(data.role && (data.role == 'iclManager' || data.role == 'admin')) return true;
      else if (data.error) alert(data.error.message);
    }

    this.router.navigate(['login']);
    return false;
  }
}
